module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7cd000a18e0b92dd47ab6a60674eb423"},
    },{
      plugin: require('../plugins/gatsby-fixr-cms-plugin/gatsby-browser.tsx'),
      options: {"plugins":[],"siteId":"site-authenticbierkeller-co-uk","baseUrl":"https://mydomain.com","name":"The Authentic Bierkeller","adminRoute":"/admin","templatePath":"/workspace/src/templates","logo":{"src":"/logo.png","alt":"The Authentic Bierkeller","width":250,"height":52}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
