import React from "react";
import { GatsbyBrowser } from "gatsby";
import {
  FixrCmsPluginOptions,
  PluginOptionsProvider,
} from "./src/fixr/components/PluginOptions";
import { Analytics } from "fixr/components/Analytics";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = (
  { element },
  options
) => {
  return (
    <PluginOptionsProvider value={options as unknown as FixrCmsPluginOptions}>
      <>
        <Analytics />
        {element}
      </>
    </PluginOptionsProvider>
  );
};
