import React from "react";
import { Script } from "gatsby";
import { useSiteData } from "fixr/hooks/useSiteData";

export function Analytics() {
  const {
    settings: { analytics },
  } = useSiteData();

  return (
    <>
      {analytics.googleMeasurementId && (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${analytics.googleMeasurementId}`}
          ></Script>
          <Script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${analytics.googleMeasurementId}}');
            `}
          </Script>
        </>
      )}
      {analytics.facebookPixelId && (
        <Script>{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${analytics.facebookPixelId}');
        fbq('track', 'PageView');
        `}</Script>
      )}
    </>
  );
}
