import type { SiteSettings } from "./useSettings";
import { graphql, useStaticQuery } from "gatsby";

type SiteData = {
  siteId: string;
  name: string;
  baseUrl: string;
  settings: SiteSettings;
  urls: { id: string; url: string }[];
};

export function useSiteData() {
  // To be replaced with graphQL query from the CMS
  const site: SiteData = {
    baseUrl: "https://authenticbierkeller.co.uk",
    name: "Authentic Bierkeller",
    urls: [],
    siteId: "authenticbierkeller-co-uk",
    settings: {
      general: {
        contactEmail: "info@authenticbierkeller.co.uk",
        contactPhone: "01522 243237",
        siteName: "Authentic Bierkeller",
        logo: {
          height: 0,
          width: 0,
          src: "",
        },
      },
      menus: {
        header: [],
        footer: [],
      },
      seo: {
        description: "",
      },
      socials: {
        facebook: "https://www.facebook.com/authenticbierkeller/",
        instagram: "https://www.instagram.com/theauthenticbierkeller/",
        tiktok: "",
        twitter: "",
        youtube: "",
      },
      analytics: {
        googleMeasurementId: "G-EJS8CQZ4ZP",
        facebookPixelId: "",
      },
    },
  };

  return site;
}
